import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ReplaceProductPageModule } from '@pages/replace-product/replace-product.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChangeAmountModalComponent } from '@components/change-amount-modal/change-amount-modal.component';
import { SubsystemModalComponent } from '@components/subsystem-modal/subsystem-modal.component';
import { SharedModule } from 'src/app/shared-module/shared-module';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { ManualComponent } from './pages/manual/manual.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ChangeAmountModalComponent,
    SubsystemModalComponent,
    ManualComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    ReplaceProductPageModule,
    SharedModule,
    SwiperModule,
    FormsModule,
  ],
  providers: [StatusBar, SplashScreen],
  bootstrap: [AppComponent],
})
export class AppModule {}
